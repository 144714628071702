<template>
	<div class="roleCheck">
		<div class="titleTxt">系统用户</div>
		<div class="row-me row-center space-between">
			<div class="row-me row-center flex-wrap">
				<div class="row-me row-center">
					<div class="width220">
						<el-select placeholder="状态" style="width: 100%;" filterable clearable v-model="state">
							<el-option v-for="item in roleArray" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="width360 posRelative margin-left10 nameBox">
					<img src="../../assets/img/icon_search.png" alt="" class="height16 width16 posAbsolute"
						style="top: 13px;left: 13px;z-index: 9;" />
					<el-input placeholder="手机号/姓名" style="width: 100%;" clearable v-model="keyword"></el-input>
				</div>

				<el-button type="primary" class="searchs" @click="clickSearch">
					<img src="../../assets/img/icon_sousuo_b.png" alt="" />
					搜索
				</el-button>
				<el-button class="agains" @click="clickClear">重置
				</el-button>
			</div>
			<div class="row-me row-center pointer">
				<div class="font-size18 font-bold color000 margin-right40" style="">
					共可添加{{user_number}}个系统用户，当前已添加{{real_number}}个，剩余{{residue}}个</div>
				<div class="addDiv row-me row-center" @click="addClick">
					<img src="../../assets/img/icon_xzyh.png" alt="" class="iconImg">
					新增用户
				</div>
			</div>
		</div>
		<div class="column-me margin-top20">
			<div class="table-border">
				<el-table :data="tableData" border header-cell-class-name="table-header-style"
					cell-class-name="table-cell-style">
					<el-table-column prop="mobile" label="手机号（登录账号）" align="center"></el-table-column>
					<el-table-column prop="account" label="用户名（可作登录账号）" align="center"></el-table-column>
					<el-table-column prop="type" label="状态" align="center">
						<template #default="scope">
							<div class="" v-if="scope.row.state == 1" style="color: #33C77C;">开启</div>
							<div class="" v-if="scope.row.state == 2" style="color: #EB4B4D;">禁用</div>
						</template>
					</el-table-column>
					<el-table-column prop="create_time" label="权限" align="center">
						<template #default="scope">
							<div class="" v-if="scope.row.is_operate == 1">仅查看</div>
							<div class="" v-if="scope.row.is_operate == 2">可编辑</div>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center">
						<template #default="scope">
							<el-button type="text" size="small" @click="editClick(scope.row)">编辑</el-button>
							<el-button type="text" size="small" @click="deleteClick(scope.row)">删除</el-button>
						</template>

					</el-table-column>
				</el-table>
			</div>
			<div class="row-me row-center flex-end1 margin-top10" v-if="tableData.length>0">
				<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
					:current-page.sync="pageNo" :total="total">
				</el-pagination>
			</div>
		</div>
		<!-- 新增用户 -->
		<el-dialog v-model="dialogUser" :visible.sync="dialogUser" v-if="dialogUser" :title="title" width="500">
			<el-form :model="form" label-width="" label-position="right">
				<el-form-item label="手机号（可作登录账号）">
					<el-input v-model="form.mobile" oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入"
						style="width: 280px;" />
				</el-form-item>
				<el-form-item label="用户名（可作登录账号）">
					<el-input v-model="form.account" placeholder="请输入" style="width: 280px;" />
				</el-form-item>
				<div class="leftDiv">
					<el-form-item label="密码">
						<el-input v-model="form.password" placeholder="请输入" style="width: 280px;" />
					</el-form-item>
				</div>
				<div class="row-me row-center margin-top20 leftDiv">
					<div class="font-size16 powerTxt">权限</div>
					<el-radio-group v-model="form.is_operate">
						<el-radio :label="1">仅查看</el-radio>
						<el-radio :label="2">可编辑</el-radio>
					</el-radio-group>
				</div>
				<div class="leftDiv">
					<el-form-item label="状态" style="margin-top: 15px;">
						<el-switch v-model="form.state">
						</el-switch>
					</el-form-item>
				</div>

			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogUser = false" class="width100height40">取消</el-button>
					<el-button type="primary" @click="ensureClick">
						确定
					</el-button>
				</span>
			</template>
		</el-dialog>

	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				keyword: '',
				state: '',
				roleArray: [{
					id: 1,
					name: '开启'
				}, {
					id: 2,
					name: '禁用'
				}],
				selectStatus: '',
				selectDate: [],
				tableData: [],
				pageNo: 1,
				total: 0,

				// 新增用户
				dialogUser: false,
				form: {
					is_operate: 1,
					mobile: '',
					account: '',
					password: '',
				},
				title: '新增用户',
				user_number: 0, //用户数量
				real_number: 0, //实际数量
				residue: 0, //剩余
			}
		},
		// 侦听器
		watch: {},
		// 计算属性
		computed: {},
		// html加载完成之前
		created() {
			this.clickSearch()
		},
		// html加载完成后执行。
		mounted() {

		},
		// 事件方法执行
		methods: {
			clickSearch() {
				this.pageNo = 1;
				this.getData();
			},
			clickClear() {
				this.keyword = '';
				this.state = '';
				this.selectDate = [];
				this.pageNo = 1;
				this.getData();
			},
			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo;
				this.getData();
			},
			//获取数据
			getData() {
				this.$post('/index/userInfoList', {
					keyword: this.keyword,
					state: this.state,
					page: this.pageNo,
				}).then((res) => {
					if (res) {
						this.total = res.data.allListNumber;
						this.tableData = res.data.listInfo;
						this.companyInfoDetailsWay();
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			companyInfoDetailsWay() {
				this.$post('/index/companyInfoDetails', {}).then((res) => {
					if (res) {
						this.user_number = res.data.user_number; //用户数量
						this.real_number = res.data.real_number; //实际数量
						this.residue = this.user_number - this.real_number; //剩余
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			editClick(row) {
				this.title = '编辑用户';
				this.dialogUser = true;
				this.form = JSON.parse(JSON.stringify(row)); //this.form为表单数据,row是表格的某一行
				if (this.form.state == 1) {
					this.form.state = true;
				} else {
					this.form.state = false;
				}
			},
			addClick() {
				this.title = '新增用户';
				this.form = this.$options.data().form;
				this.dialogUser = true;
			},
			// 点击确定
			ensureClick() {
				if (this.form.mobile == "" || this.form.mobile == undefined) {
					this.$message.error("手机号不能为空")
					return false;
				}

				if (!(/^1[3456789]\d{9}$/.test(this.form.mobile))) {
					this.$message.error("手机号有误，请重新输入")
					return false;
				}

				if (this.form.account == "" || this.form.account == undefined) {
					this.$message.error("用户名不能为空")
					return false;
				}
				if (this.form.password == "" || this.form.password == undefined) {
					this.$message.error("密码不能为空")
					return false;
				}

				if (this.form.state == true) {
					this.form.state = 1;
				} else {
					this.form.state = 2;
				}
				this.$post('/index/userInfoAdd', this.form).then((res) => {
					if (res) {
						this.form = {}
						this.$message.success('提交成功')
						this.dialogUser = false;
						this.getData()
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			deleteClick(row) {
				this.$confirm("确定删除该用户吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.$post('/index/userInfodel', {
						id: row.id
					}).then((res) => {
						if (res) {
							this.$message.success('删除成功')
							this.getData()
						}
					}).catch((error) => {
						this.$message.error(error.message)
					})
				}).catch(() => {});
			},
		},
	}
</script>

<style lang="scss" scoped>
	/deep/.el-dialog__headerbtn .el-dialog__close {
		color: #999;
	}

	/deep/.el-dialog__header {
		background-color: #fff;
		border-bottom: 1px solid #D6D9E2;

		.el-dialog__title {
			color: #000000;
		}
	}

	/deep/.el-form-item__label {
		height: 40px;
		line-height: 40px;
		font-size: 16px;
		color: #252630;
	}

	/deep/.el-switch {
		width: 60px !important;
		height: 30px !important;

		.el-switch__core {
			width: 60px !important;
			height: 30px !important;
			border-radius: 15px;
		}

		.el-switch__action {
			width: 24px !important;
			height: 24px !important;
		}

	}

	/deep/.el-radio__label {
		font-size: 16px !important;
	}

	/deep/.el-switch.is-checked .el-switch__core .el-switch__action {
		left: calc(100% - 0.4rem);
	}

	.powerTxt {
		margin-right: 15px;
	}

	.leftDiv {
		margin-left: 145px;
	}


	.roleCheck {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;

		.titleTxt {
			font-size: 24px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #252630;
			padding-bottom: 30px;
		}

		.addDiv {
			width: 128px;
			height: 40px;
			line-height: 40px;
			border: 1px solid #D4D7E0;
			border-radius: 4px;

			.iconImg {
				width: 26px;
				height: 26px;
				margin-right: 10px;
				margin-left: 10px;
			}
		}

		.nameBox {
			/deep/.el-input__inner {
				padding-left: 30px;
			}
		}

		.agains {
			margin-left: 10px;
			width: 100px;
			height: 40px;
			font-size: 16px;
		}

		.searchs {
			margin-left: 10px;
			font-size: 16px;

			img {
				width: 16px;
				height: 16px;
				margin-right: 10px;
			}
		}

		.title {
			font-size: 25px;
			font-weight: bold;
		}

		.left-title {
			width: 150px;
		}

		.img-head {
			width: 80px;
			height: 80px;
			border-radius: 50%;
		}

		.img-photo {
			width: 130px;
			height: 100px;
			margin-right: 20px;
			margin-bottom: 10px;
		}
	}
</style>